<template>
  <div class="login auth-page" id="auth" :style="authStyle">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card card-container mt-5">
            <form class="form-recover" @submit.prevent="recover">
              <h5>{{ 'login.recoverPassword' | translate }}</h5>
              <p>{{ $t('recoverPassword.infoText', { backToLogin: $t('recoverPassword.backToLogin') }) }}</p>
              <input
                type="email"
                id="inputEmail"
                class="form-control"
                placeholder="Email address"
                required
                autofocus
                v-model="email"
              />
              <br />
              <primary-button :loading="loading" size="large" class="btn-block btn-signin" type="submit">{{
                'settings.confirm' | translate
              }}</primary-button>
            </form>
            <br />
            <router-link to="login" class="auth-link mt-1">{{ 'recoverPassword.backToLogin' | translate }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authMixin from '../../mixins/auth';

export default {
  mixins: [authMixin],
  data() {
    return {
      email: '',
      loading: false
    };
  },
  methods: {
    recover() {
      this.loading = true;
      this.$http
        .post('forgotpassword', {
          email: this.email,
          subdomain: this.$store.getters['settings/subdomain']
        })
        .then(
          () => {
            this.loading = false;
            this.showSuccessNotification(this.$t('login.forgotyourpasswordmessage', { email: this.email }));

            setTimeout(this.$router.push({ path: '/auth/login' }), 1000);
          },
          (error) => {
            let errorMessage = this.$t('notifications.error');
            if (error?.response?.data?.error === 'user_not_activated') {
              errorMessage = this.$t('notifications.error.accountNotActivated');
            } else if (error?.response?.data?.error === 'email_not_found') {
              errorMessage = this.$t('notifications.emailNotFound');
            }
            this.showErrorNotification(errorMessage);
            this.loading = false;
          }
        );
    }
  }
};
</script>

<style lang="scss" scoped>
@import './auth.scss';
</style>
